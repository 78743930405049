/* [class$="-col"] */

span[class^="toggle_"] svg {
    transition: all 0.3s;
}

.modalBody {
    font-size: 1.2em
}

.deviceListRoot > div {
    border-bottom: 1px dotted #00000033;
    padding: 5px 0;
}