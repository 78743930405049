.inputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.inputField input[type=text] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=text]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=text]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.inputField input[type=text]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 1; /* Firefox */
}
  
.inputField input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.inputField input[type=number] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=number]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=number]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.inputField input[type=number]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=number]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 1; /* Firefox */
}
  
.inputField input[type=number]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=number]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.right {
    display: flex;
    justify-content: right;
    align-items: right;
    right: 0px;
    padding: 10px;
}