.mainWrapper {
    display: flex;
    background-color: #1b2540;
    color: #fff;
}

.mainContentWrapper {
    width: 95vw;
    padding-top: 7vh; /* height offset for header */
    display: flex;
    flex-flow: column;
}

.mainContentOuter {
    width: 100%;
    height: 100%;
    padding: 10px;
    /* padding-top: 1%; */
    /* padding-bottom: 2%; */
    position: relative;
}