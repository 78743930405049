@media only screen and (min-width: 768px) {
    .scrollbar_smc {
        overflow: auto;
    }
    .scrollbar_smc::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
        
    .scrollbar_smc::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;
    }
        
    .scrollbar_smc::-webkit-scrollbar-track {
        background: #ffffff30;
        border-radius: 20px;
    }
}