.sessionReminder {
    font-size: 18px;
    /* display: none; */
    position: fixed;
    z-index: 100;
    background: white;
    color: black;
    width: 500px;
    height: 120px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}

.sessionReminder p {
    color: black !important;
    font-size: inherit
}

.sessionReminder button {
    padding: 5px 15px 5px 15px;
    margin-right: 20px;
    border: 2px solid #6c6c6c;
}

.backdrop {
    background: rgba(0,0,0,0.7);
    position: fixed;
    display: none;
    z-index: 99;
    width: 100%;
    height: 100%;
    left:0;
    right:0;
    top:0;
    bottom:0;
}