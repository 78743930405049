.checkbox {
    height: 15px;
    width: 15px;
    background-color: #eee;
}

.navButtons {
    border: 1px solid #000 !important;
    box-shadow: 1px 1px 1px #000;
}

.tableFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}

table.technologies {
    border-collapse: collapse;
    width: 100%;
    color: #000;
    font-size: 1.1em;
}
  
table.technologies td, th {
    border: 1px solid #ddd;
    padding: 8px;
}
  
table.technologies tr:nth-child(even){background-color: #ced1da;}
table.technologies tr:nth-child(odd){background-color: #999ead;}
  
table.technologies tr:hover {background-color: #ddd;}
  
table.technologies th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2b3348;
    color: white;
}

.inputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.inputField div{
    display: inline;
    padding: 10px;
}

.inputField input[type=text] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=text]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=text]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.inputField input[type=text]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 1; /* Firefox */
}
  
.inputField input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.inputField input[type=number] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=number]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=number]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.inputField input[type=number]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=number]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 1; /* Firefox */
}
  
.inputField input[type=number]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=number]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.right {
    display: flex;
    justify-content: right;
    align-items: right;
    right: 0px;
    padding: 10px;
}