.image {
    width: fit-content;
    height: fit-content;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
}

.image img{
    width: 55px;
    height: 55px;
}

.content {
    text-align: center;
    padding: .5rem;
    margin: 20px 0;
}

.content h1{
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;
    text-shadow: 2px 2px 3px #000;
    text-align: center;
}

.content p{
    font-size: 1.0rem;
    color: #242C39;
    margin: 0;
}

.actions {
    padding-bottom: 1rem;
    text-align: center;
}

.actions button{
    font: inherit;
    cursor: pointer;
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
}

.actions button:hover,
.actions button:active{
    background-image: linear-gradient(
        135deg, rgb(61 2 179) 0%,rgb(0 128 154) 100%);
}

.icon{
    margin-top: 50px;
}