.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 10px;
    justify-items: center;
}
.gridItem {
    width: 150px;
    padding: 0px;
    padding-bottom: 20px;
    justify-items: center;
    cursor: pointer;
}