.deviceNumber {
    float:right;
    line-height:1; 
    font-size:35px;
    cursor:pointer;
    position:relative;
    border-bottom:1px dotted #76a6ee;
}
.inputWrapper {
    z-index:2;
    width:100%;
    color:#fff;
    padding:5px;
    font-size:22px;
    position:relative;
    border:1px solid #76a6ee;
    background:rgb(5, 39, 88);
}
.searchInput {
    border: 0;
    background:transparent;
    width:80%;
    color:#fff;
}
.searchInput:focus {
    outline: 0; 
}
.radarChart {
    height: 100%;
    margin-top:15px;
    border-style:solid;
    border-width:1;
    border-color:#007acc;
    box-shadow:8px 2px 15px #000;
}
.technologyTrendsData {
    padding: 40px 40px 0 40px;
}

.searchResults {
    background: #052758;
    z-index: 2;
    position: absolute;
    width: 100%;
    padding: 0 10px;
}
.searchResults div {
    padding:5px;
}
.searchResults div > p {
    display: inline;
    font-size: 1.3em;
    cursor:pointer;
}
.searchResults div > p:hover {
    border-bottom: 1px dotted #b5b5b5;
}

.searchInputWrapper {
    width: 80%;
    display: none;
    position: absolute;
}

.inputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.inputField input[type=text] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=text]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=text]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.2; /* Firefox */
}

.inputField input[type=text]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 0.2; /* Firefox */
}
  
.inputField input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

@media only screen and (max-width:768px) {
    .searchInputWrapper {
        width: 100%;
    }
}