.loadingCoverWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.loaderPosition {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loading {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.verticalCenter {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* Shimmer Loader
   Ref: https://codepen.io/bloom-dan/pen/OXYQay
*/

.loadingShimmer {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: transparent;
    background-image: linear-gradient(to right, transparent 0%, #64646446 20%, transparent 40%, transparent 100%);
    background-repeat: no-repeat;
    background-size: 800px;
    /* height: 104px;
    width: 104px; */
    position: relative;
}

@keyframes placeHolderShimmer{
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}