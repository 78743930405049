  
.accordion {
    color: black;
    margin: 0rem auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    background: rgb(238, 174, 202);
    background: radial-gradient(
      circle,
      rgba(238, 174, 202, 1) 0%,
      rgba(199, 233, 148, 1) 100%
    );
}
  
.accordionTitle {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
    background-color: #CDCDCD;
    color: black;
}
  
.accordionTitle:hover {
    background-color: #B0B0B0;
}
  
.accordionTitle,
.accordionContent {
    padding: 1rem;
}
  
.accordionContent {
    background-color: #FFFFFF;
    color: black;
}
  
