.inputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.inputField input {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.inputField input:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* color: rgb(247, 247, 247); */
    opacity: 1; /* Firefox */
}
  
.inputField input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}



.right {
    display: flex;
    justify-content: right;
    align-items: right;
    right: 0px;
    padding: 10px;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    padding: 10px;
}



