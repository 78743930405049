.navigationSidebar {
    width: 5vw;
    background: #2b3348;
}

.navigationWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    height: 95%;
    color: #cacaca;
}

.navigationWrapper > div {
    width: 100%;
    height: 80px;
    /* background:red; */
    text-align: center;
    align-items: center;
    vertical-align: middle;
    font-size: 34px;
    position: relative;
    cursor: pointer;
}

.navigationWrapper a {
    font-size: inherit;
    text-decoration: none;
    color: inherit;
}
.navigationWrapper > div > a:hover {
    color: #97beef
}
.navigationWrapper > div:hover > .submenu {
    display: block;
}
.submenu {
    position: absolute;
    z-index: 100;
    left: 100%;
    top: 0;
    min-width: 180px;
    width: fit-content;
    text-align: left;
    backdrop-filter: blur(8px);
    list-style: none;
    background: #00000088;
    font-size: 0.45em;
    padding: 5px;
    text-shadow: 2px 2px 2px black;
    display: none;
}
.submenu a {
    display: list-item;
    padding: 5px 20px;
}
.submenu a:hover {
    text-decoration: underline;
    color: inherit;
}


/* .navigationWrapper div ul.subMenu {
    background: red;
    font-size: 18px;
    list-style: none;
    position: absolute;
    left: 5vw;
    top: 0;
    padding-inline-start: 0;
    display: none;
}
.navigationWrapper div:hover > ul.subMenu {
    background: blue;
    display: block;
} */