.header {
    background: #2b3348;
    height: 7vh;
    margin-top: -7vh; /* pull header up, space made available by mainContentWrapper */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoGroup {
    font-size: 24px;
    position: relative;
    display: flex;
    align-items: center;
}
.logoGroup i::before {
    content: "";
    width: 2px;
    height: 70%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgb(167, 167, 167) 50%, rgba(0,0,0,0) 100%);;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}