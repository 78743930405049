.wrapper {
    /* background: #c02d2d; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 2px 6px #0f1933;
    margin-top: 4px;
    color: blanchedalmond;
}
.wrapper .score {
    font-size: 3rem; 
    margin-right: 10px;
}
.title {
    padding-top: 08%;
    font-weight: 10;
}