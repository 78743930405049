html {
  position:relative;
  width:100%;
  height: 100%;
  overflow:hidden;
}


body {
  width:100%;
  height: 100%;
  margin: 0;
  font-family: Ubuntu Mono, monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > #root > div {
  height: 100vh;
}

body > #root div,
body > #root p {
  color:#fff;
}