.subcategory {
    flex: none;
    width: 210px;
}

.subcatWrapper {
    display:grid; 
    cursor: pointer;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
}

.scoreWrapper {
    display: flex;
    justify-content: center;
    width: 10em;
}
.score {
    font-size: 2em;
    font-weight: normal;
    text-shadow: 2px 2px 2px #333;
}
.arrowUp {
    color:#6ad72d;
}
.arrowDown {
    color:#ff2c00;
}

.subcatTitle {
    width: 95%;
    text-align: center;
    font-size: .7em;
    color: rgba(126, 139, 233, 1) !important;
    text-shadow: 0px 2px 2px #000000c7;
    font-family: Ubuntu Mono, monospace !important;
}