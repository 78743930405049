.card{
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    background-color: #2b3348;
    /* background-image: linear-gradient(to bottom right, rgb(53 0 93 / 97%),rgb(0 130 78)); */
    box-shadow: 0 2px 10px #000;
    padding: 10px;
    height: 100%;
}

.card:hover {
    box-shadow: 0 10px 15px #000;
}
  
.container {
    padding: 2px 16px;
}
