.wrapper {
    font-size: 1.3em;
    width: 50% !important;
    height: fit-content !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.inputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.inputField input[type=password] {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid white;
    color: white;
}

.inputField input[type=password]:focus {
    border: 3px solid #555;
    background-color: white;
    color: black;
}

.inputField input[type=password]:focus::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.2; /* Firefox */
}

.inputField input[type=password]:focus::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
}

.inputField input[type=password]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(247, 247, 247);
    opacity: 0.2; /* Firefox */
}
  
.inputField input[type=password]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}
  
.inputField input[type=password]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.right {
    display: flex;
    justify-content: right;
    align-items: right;
    right: 0px;
    padding: 10px;
}