.scoreFlexWrap {
    display:flex;
}

.separator {
    background: linear-gradient(180deg,hsla(0,0%,77.6%,0) 20%,rgba(100,100,100,.8) 50%,hsla(0,0%,77.6%,0) 80%);
    width: 1px;
    position: absolute;
    top:0;
    right:0;
    height:80%;
    top:50%;
    transform: translateY(-50%);
}

.scoreFlexWrap > div:last-child .separator {
    display:none;
}