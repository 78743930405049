table.technologies {
    border-collapse: collapse;
    width: 100%;
    color: #000;
}
  
table.technologies td, th {
    border: 1px solid #ddd;
    padding: 8px;
}
  
table.technologies tr:nth-child(even){background-color: #ced1da;}
table.technologies tr:nth-child(odd){background-color: #999ead;}
  
table.technologies tr:hover {background-color: #ddd;}
  
table.technologies th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2b3348;
    color: white;
}

.navButtons {
    border: 1px solid #000 !important;
    box-shadow: 1px 1px 1px #000;
}

p,
label {
    font: 0.9rem;
}

input {
    margin: .4rem;
}

.optionsHeader {
    background: #22335f;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

select, input[type=date] {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(118, 166, 238, 0.5);
}

input[type=checkbox] {
    width: 15px;
    height: 15px;
    background-color: #eee;
}