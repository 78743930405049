.headerNavigation {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.userGreeting {
    font-size: 13px;
    margin-right: 20px;
}

.userActions {
    display: flex;
    font-size: 28px;
}
.userActions > div {
    margin: 0 20px;
    position: relative;
    cursor: pointer;
}

.userActions > div:hover div.submenu {
    display: block;
}

.userActions .userOnlineStatus::after {
    content: '';
    width:12px;
    height: 12px;
    background:#15d04a;
    border-radius: 50%;
    border: 1px solid #444;
    position: absolute;
    bottom: 0;
    right: 0;
}

.userActions .notificationCount::after {
    content: '6';
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    width:18px;
    height: 18px;
    background:#ff0200;
    border-radius: 50%;
    border: 1px solid #444;
    position: absolute;
    top: 5px;
    right: -3px;
}

.submenu {
    position: absolute;
    z-index: 100;
    left: 0;
    min-width: 170px;
    padding: 10px !important;
    text-align: left;
    backdrop-filter: blur(8px);
    /* list-style: none; */
    background: #00000088;
    font-size: 0.55em;
    padding: 5px;
    text-shadow: 2px 2px 2px black;
    display: none;

}
.submenu a {
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    display: list-item;
    list-style: none;
}
.submenu a:hover {
    color: #fff;
    text-decoration: underline;
}