.checkbox {
    height: 15px;
    width: 15px;
    background-color: #eee;
}

.navButtons {
    border: 1px solid #000 !important;
    box-shadow: 1px 1px 1px #000;
}

.tableFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}

table.technologies {
    border-collapse: collapse;
    width: 100%;
    color: #000;
    font-size: 1.1em;
}
  
table.technologies td, th {
    border: 1px solid #ddd;
    padding: 8px;
}
  
table.technologies tr:nth-child(even){background-color: #ced1da;}
table.technologies tr:nth-child(odd){background-color: #999ead;}
  
table.technologies tr:hover {background-color: #ddd;}
  
table.technologies th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2b3348;
    color: white;
}