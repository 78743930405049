
/*
 * Box Wrapper Layout
 */
 .dashboardBoxWrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    background: #2b3348;
    margin: 0 5px;
    padding: 10px;
}
.dashboardBoxWrapper > div {
    height: 100%;
}

.scoreBoxesWrapper {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    text-shadow: 1px 1px 1px #000;
}

.scoreBoxesWrapper > div {
    display: flex;
    justify-content: center;
    margin-bottom: -1%;
    width: 24%;
    height: 90%;
}