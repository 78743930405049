.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    grid-column-gap: 15px;
    justify-items: center;
}

.gridItem {
    width: 100%;
    height: 350px;
    padding: 0px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    justify-items: center;   
    color: black
}

.customCard{
    height: 350px;
    border: 2px solid black;
    /* padding: 10px; */
    color: black;
    border-radius: 10px;
    box-shadow: 0 2px 10px #000;
    background-color: #2b3348;
    /* background-image: linear-gradient(to bottom right, rgb(53 0 93 / 97%),rgb(0 130 78)); */
}

.customCard:hover {
    box-shadow: 0 10px 15px #000;
}