.flexContainer{
  background-color:#021735 !important;
  padding:1%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 700px;
}

.dots {
  color:#fff;
  font-family: Ubuntu Mono, monospace;
}

.dots span {
  animation-name: blink;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dots span:nth-child(2) {
  animation-delay: .2s;
}

.dots span:nth-child(3) {
  animation-delay: .4s;
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}