.progressBarWrapper {
    width: 100%;
    height: fit-content;
}

.progressBars {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 0px;
    margin-bottom: 5%;
    margin-top: 4%;
}

.textGroup {
    display: flex;
    margin-bottom: 10px;
}
.textGroup span:first-child {
    flex: 0 1 100%;
}

.progress {
    position: absolute;
    border-radius: 10px;
    height: 10px;
    width: 60%;
    background: linear-gradient(180deg, rgba(2,217,255,1) 0%, rgba(74,122,255,1) 100%);;
    z-index: 2;
}

.background {
    position: absolute;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    background: #99999933;
    z-index: 1;
}

