.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    grid-column-gap: 10px;
    justify-items: center;
}

@media screen and (max-width: 1200px) {
    .gridContainer {
        grid-template-columns: auto auto auto;
    }
}

@media screen and (max-width: 900px) {
    .gridContainer {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 600px) {
    .gridContainer {
        grid-template-columns: auto;
    }
}


.gridItem {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-bottom: 20px;
    justify-items: center;
}